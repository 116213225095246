<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('survey_questions')"
                        :isNewButton="true"
                        :is-filter="false"
                        @new-button-click="addSection">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('survey_questions')"
                              :isNewButton="true"
                              :is-filter="false"
                              @new-button-click="addSection">
                </HeaderMobile>
            </template>

            <h4 v-if="survey">{{ survey.name }}</h4>
            <div v-for="section in data" class="mb-5 border rounded p-4 mb-4 w-100">
                <div class="d-block d-md-flex align-items-center mb-4">
                    <h5 class="mr-5 mb-2 mb-md-0">{{section.name}}
                    </h5>
                    <div class="d-flex">
                        <b-button variant="outline-primary" class="mr-2" @click="addQuestion(section)"><i class="ri-add-line mr-2"></i>{{$t('new_question')}}</b-button>
                        <b-button variant="outline-secondary" class="mr-2" @click="editSection(section)"><i class="ri-pencil-line align-middle top-minus-1 mr-3 text-muted"></i>{{$t('edit')}}</b-button>
                        <b-button variant="outline-danger" @click="deleteSection(section.id)">{{$t('delete')}}</b-button>
                    </div>
                </div>
                <div class="accordion" role="tablist">
                        <b-card no-body class="mb-1 border-0" :key="question.id" v-for="(question, index) in section.questions">
                          <b-card-header header-tag="header" class="px-0 py-1 border-0 bg-transparent" role="tab">
                            <b-button block v-b-toggle="'accordion'+index" variant="outline-secondary py-2">
                              <div class="d-block w-100">
                                <div class="text-left mb-1 mb-lg-0">{{question.question}}</div>
                              </div>
                            </b-button>
                          </b-card-header>
                          <b-collapse :id="'accordion'+index" accordion="my-accordion" role="tabpanel">
                            <b-card-body class="px-0 py-3">
                        <div class="d-flex mb-2">
                            <b-button variant="outline-primary" v-if="question.type==1" class="mr-2" @click="addOption(question)"><i class="ri-add-line mr-2"></i>{{$t('add_option')}}</b-button>
                            <b-button variant="outline-secondary" class="mr-2" @click="editQuestion(question)"><i class="ri-pencil-line align-middle top-minus-1 mr-3 text-muted"></i>{{$t('edit')}}</b-button>
                            <b-button variant="outline-danger" @click="deleteQuestion(question.id)" v-if="!question.options">{{$t('delete')}}</b-button>
                        </div>
                         <div class="ml-5" v-if="question.type==1">
                                <ol class="m-0 p-0">
                                    <li v-for="option in question.options" class="mb-2">
                                        <div class="mb-1 h6">{{option.option}}</div>
                                        <div class="d-flex">
                                            <b-button variant="outline-secondary" class="mr-2" @click="editOption(option)"><i class="ri-pencil-line align-middle top-minus-1 mr-3 text-muted"></i>{{$t('edit')}}</b-button>
                                            <b-button variant="outline-danger" @click="deleteOption(option.id)">{{$t('delete')}}</b-button>
                                        </div>
                                    </li>
                                </ol>
                            </div>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </div>
                <!-- <div v-for="question in section.questions">
                    <h6>{{question.question}}</h6>
                    <div class="d-flex">
                        <b-button variant="outline-primary" class="mr-2" @click="addOption(question)">{{$t('add_option')}}</b-button>
                        <b-button variant="outline-primary" class="mr-2" @click="editQuestion(question)">{{$t('edit')}}</b-button>
                        <b-button variant="outline-danger" @click="deleteQuestion(question.id)" v-if="!question.options">{{$t('delete')}}</b-button>
                    </div>
                    <div>
                        <ol>
                            <li v-for="option in question.options">
                                <div>{{option.option}}</div>
                                <div class="d-flex">
                                    <b-button variant="outline-primary" class="mr-2" @click="editOption(option)">{{$t('edit')}}</b-button>
                                    <b-button variant="outline-danger" @click="deleteOption(option.id)">{{$t('delete')}}</b-button>
                                </div>
                            </li>
                        </ol>
                    </div>
                </div> -->
            </div>
            <CommonModal id="modalSection" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t(formSection.id > 0 ? "edit_part" : "new_part") }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="formSection">
                        <div class="row mb-5 align-items-center">
                            <b-col cols="12" lg="6">
                                <b-form-group :label="$t('name')">
                                    <ValidationProvider name="name" rules="required" v-slot="{valid, errors}">
                                        <b-input-group class="mb-1" >
                                            <b-form-input v-model="formSection.name"
                                                          :state="errors[0] ? false : null"/>
                                        </b-input-group>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <b-form-group :label="$t('explanation')">
                                    <ValidationProvider name="explanation" rules="" v-slot="{valid, errors}">
                                        <b-input-group class="mb-1" >
                                            <b-form-textarea v-model="formSection.explanation"
                                                             :state="errors[0] ? false : null"/>
                                        </b-input-group>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <b-form-group :label="$t('rank')">
                                    <ValidationProvider name="rank" rules="required" v-slot="{valid, errors}">
                                        <b-form-input v-model="formSection.rank" type="number" step="1"/>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                            <div class="col-12 mt-3 d-flex">
                                <b-button
                                    @click="storeSection"
                                    type="button"
                                    variant="primary"
                                    class="btn-lg mr-2"
                                >
                                    {{ $t("save") | toUpperCase }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
            <CommonModal id="modalQuestion" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t(formQuestion.id > 0 ? "edit_question" : "new_question") }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="formQuestion">
                        <div class="row mb-5 align-items-center">
                            <b-col cols="12">
                                <b-form-group :label="$t('question')">
                                    <ValidationProvider name="question" rules="required" v-slot="{valid, errors}">
                                        <b-input-group class="mb-1" >
                                            <b-form-input v-model="formQuestion.question"
                                                          :state="errors[0] ? false : null"/>
                                        </b-input-group>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <b-form-group :label="$t('rank')">
                                    <ValidationProvider name="rank" rules="required" v-slot="{valid, errors}">
                                        <b-form-input v-model="formQuestion.rank" type="number" step="1"/>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <b-form-group :label="$t('type')">
                                    <ValidationProvider name="type" rules="required" v-slot="{valid, errors}">
                                        <multi-selectbox v-model="formQuestion.type" :options="[{value:1, text: $t('multiple_choice')},{value:2, text: $t('comment')}]"
                                                         :multiple="false"
                                                         :validate-error="errors[0]"/>
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('status')">
                                        <status-selectbox v-model="formQuestion.status"
                                                          :validate-error="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <ValidationProvider name="is_required" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('is_required')">
                                        <true-false-selectbox v-model="formQuestion.is_required"
                                                              :validate-error="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <div class="col-12 mt-3 d-flex">
                                <b-button
                                    @click="storeQuestion"
                                    type="button"
                                    variant="primary"
                                    class="btn-lg mr-2"
                                >
                                    {{ $t("save") | toUpperCase }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
            <CommonModal id="modalOption" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t(formOption.id > 0 ? "edit_option" : "new_option") }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="formOption">
                        <div class="row mb-5 align-items-center">
                            <b-col cols="12">
                                <b-form-group :label="$t('option')">
                                    <ValidationProvider name="option" rules="required" v-slot="{valid, errors}">
                                        <b-input-group class="mb-1" >
                                            <b-form-input v-model="formOption.option"
                                                          :state="errors[0] ? false : null"/>
                                        </b-input-group>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <b-form-group :label="$t('value')">
                                    <ValidationProvider name="value" rules="required" v-slot="{valid, errors}">
                                        <b-form-input v-model="formOption.value" type="number" step="1"/>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <b-form-group :label="$t('rank')">
                                    <ValidationProvider name="rank" rules="required" v-slot="{valid, errors}">
                                        <b-form-input v-model="formOption.rank" type="number" step="1"/>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>

                            <div class="col-12 mt-3 d-flex">
                                <b-button
                                    @click="storeOption"
                                    type="button"
                                    variant="primary"
                                    class="btn-lg mr-2"
                                >
                                    {{ $t("save") | toUpperCase }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import qs from "qs";
import SurveyService from "@/services/SurveyService";
import SurveySectionService from "@/services/SurveySectionService";
import SurveyQuestionService from "@/services/SurveyQuestionService";
import SurveyOptionService from "@/services/SurveyOptionService";
import CommonModal from "@/components/elements/CommonModal";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";
import TrueFalseSelectbox from "@/components/interactive-fields/TrueFalseSelectbox";

export default {
    components: {
        TrueFalseSelectbox,
        MultiSelectbox,
        AppLayout,
        Header,
        HeaderMobile,
        CommonModal,
        ValidationProvider,
        ValidationObserver,
        StatusSelectbox,
    },
    metaInfo() {
        return {
            title: this.$t("survey_questions"),
        };
    },
    data() {
        return {
            id: this.$route.params.id,
            form: {},
            formSection: {},
            formQuestion: {is_required:true},
            formOption: {},
            data: null,
            survey: null,
        };
    },
    methods: {
        getSections() {
            const config = {
                params: {
                    filter:{
                        survey_id: this.id,
                    },
                    sort: 'rank'
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };

            return SurveySectionService.getAll(config)
                .then((response) => {

                    this.data = response.data.data;
                    this.getQuestions();
                });
        },
        getQuestions() {
            const config = {
                params: {
                    filter:{
                        survey_id: this.id,
                    },
                    sort: 'rank',
                    include: ['survey','surveySection','options']
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };

            return SurveyQuestionService.getAll(config)
                .then((response) => {
                    this.setQuestions(response.data.data)
                });
        },
        setQuestions(questions) {
            questions = this.groupBy(questions, 'survey_section_id');
            this.data = this.data.map(item => {
                item.questions = questions[item.id];
                return item;
            });
        },
        getSurvey() {
            if (this.id > 0) {
                SurveyService.get(this.id)
                    .then((response) => {
                        this.survey = response.data.data;
                    })
                    .catch((error) => {
                        if (error.data.message) {
                            this.$toast.error(this.$t("api." + error.data.message));
                        }
                    });
            }
        },
        async storeSection() {
            const isValid = await this.$refs.formSection.validate();
            if (isValid) {
                if (this.formSection.id > 0) {
                    SurveySectionService.update(this.formSection.id, this.formSection)
                        .then((response) => {
                            this.$toast.success(this.$t("api." + response.data.message));
                            this.$bvModal.hide('modalSection');
                        })
                        .catch((e) => {
                            if (e.data.message) {
                                this.$toast.error(this.$t("api." + e.data.message));
                            }
                        });
                } else {
                    this.formSection.survey_id = this.id;
                    SurveySectionService.store(this.formSection)
                        .then((response) => {
                            this.$toast.success(this.$t("api." + response.data.message));
                            this.$bvModal.hide('modalSection');
                            this.getSections();
                        })
                        .catch((e) => {
                            if (e.data.message) {
                                this.$toast.error(this.$t("api." + e.data.message));
                            }
                        });
                }
            }
        },
        async storeQuestion() {
            const isValid = await this.$refs.formQuestion.validate();
            if (isValid) {
                if(this.formQuestion.is_required===false){
                    this.formQuestion.is_required=0;
                }
                if (this.formQuestion.id > 0) {
                    SurveyQuestionService.update(this.formQuestion.id, this.formQuestion)
                        .then((response) => {
                            this.$toast.success(this.$t("api." + response.data.message));
                            this.$bvModal.hide('modalQuestion');
                            this.getQuestions()
                        })
                        .catch((e) => {
                            if (e.data.message) {
                                this.$toast.error(this.$t("api." + e.data.message));
                            }
                        });
                } else {
                    this.formQuestion.survey_id = this.id;
                    this.formQuestion.survey_section_id = this.formSection.id;
                    SurveyQuestionService.store(this.formQuestion)
                        .then((response) => {
                            this.$toast.success(this.$t("api." + response.data.message));
                            this.$bvModal.hide('modalQuestion');
                            this.getQuestions()
                        })
                        .catch((e) => {
                            if (e.data.message) {
                                this.$toast.error(this.$t("api." + e.data.message));
                            }
                        });
                }
            }
        },
        async storeOption() {
            const isValid = await this.$refs.formOption.validate();
            if (isValid) {
                if (this.formOption.id > 0) {
                    SurveyOptionService.update(this.id, this.formOption)
                        .then((response) => {
                            this.$toast.success(this.$t("api." + response.data.message));
                            this.$bvModal.hide('modalOption');
                            this.getQuestions()
                        })
                        .catch((e) => {
                            if (e.data.message) {
                                this.$toast.error(this.$t("api." + e.data.message));
                            }
                        });
                } else {
                    this.formOption.survey_question_id = this.formQuestion.id;
                    SurveyOptionService.store(this.formOption)
                        .then((response) => {
                            this.$toast.success(this.$t("api." + response.data.message));
                            this.$bvModal.hide('modalOption');
                            this.getQuestions()
                        })
                        .catch((e) => {
                            if (e.data.message) {
                                this.$toast.error(this.$t("api." + e.data.message));
                            }
                        });
                }
            }
        },
        deleteSection(id) {
            this.$swal.fire({
                text: this.$t('are_you_sure_to_delete'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        SurveySectionService.del(id)
                            .then(response => {
                                this.toast(response.data);
                                this.getSections()
                            })
                            .catch(e => {
                                this.showErrors(e);
                            })
                    }
                })
        },
        deleteQuestion(id) {
            this.$swal.fire({
                text: this.$t('are_you_sure_to_delete'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        SurveyQuestionService.del(id)
                            .then(response => {
                                this.toast(response.data);
                                this.getQuestions()
                            })
                            .catch(e => {
                                this.showErrors(e);
                            })
                    }
                })
        },
        deleteOption(id) {
            this.$swal.fire({
                text: this.$t('are_you_sure_to_delete'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        SurveyOptionService.del(id)
                            .then(response => {
                                this.toast(response.data);
                                this.getQuestions()
                            })
                            .catch(e => {
                                this.showErrors(e);
                            })
                    }
                })
        },
        addSection() {
            this.$refs.formSection.reset();
            this.formSection = {};
            this.$bvModal.show('modalSection');
        },
        addQuestion(section) {
            this.formSection = section;
            this.$refs.formQuestion.reset();
            this.formQuestion = {is_required:true};
            this.$bvModal.show('modalQuestion');
        },
        addOption(question) {
            this.formQuestion = question;
            this.$refs.formOption.reset();
            this.formOption = {};
            this.$bvModal.show('modalOption');
        },
        editSection(section) {
            this.$refs.formSection.reset();
            this.formSection = section;
            this.$bvModal.show('modalSection');
        },
        editQuestion(question) {
            this.$refs.formQuestion.reset();
            this.formQuestion = question;
            this.$bvModal.show('modalQuestion');
        },
        editOption(option) {
            this.$refs.formOption.reset();
            this.formOption = option;
            this.$bvModal.show('modalOption');
        },
    },
    created() {
        this.getSurvey();
        this.getSections();
    }
};
</script>

